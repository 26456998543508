import React from 'react'

import styled from 'styled-components'
import FlexBox from 'components/FlexBox'
import { uniq } from 'lodash'
import { anyShipmentAttributeUpdated } from './utils'

const StyledDiv = styled.div`
  font-size: 13px;
  color: #5c5c5c;
  font-weight: bold;
  display: flex;
  align-items: center;
`

const StyledNumeric = styled.span`
  font-size: 18px;
  color: #5c5c5c;
  font-weight: bold;
  margin-right: 5px;
`

const Summary = (props: any) => {
  const { shipments } = props
  const purchaseOrderNumbers = uniq(
    shipments
      .map((shipment: any) => {
        return shipment.purchaseOrderNumber
      })
      .filter(Boolean),
  )

  const shipmentsReviewedTracker = shipments
    .map((shipment: any) => {
      return (
        shipment.currentShipmentStatus === 'Content Review Required' &&
        !shipment.toBeCancelled
      )
    })
    .filter(Boolean)

  const shipmentsCancelledTracker = shipments
    .map((shipment: any) => {
      return shipment.toBeCancelled
    })
    .filter(Boolean)

  const shipmentsModifiedTracker = shipments
    .map((shipment: any) => {
      return anyShipmentAttributeUpdated(shipment)
    })
    .filter(Boolean)

  const renderSummaryTag = (
    summaryTag: any,
    description: string,
    pluralizedDescription: string,
    renderIcon: boolean,
  ) => {
    if (summaryTag.length) {
      return (
        <>
          {renderIcon && (
            <span className="hc-ml-normal hc-mr-normal hc-mt-min hc-clr-grey03">
              &
            </span>
          )}
          <StyledNumeric> {summaryTag.length} </StyledNumeric>
          <StyledDiv>
            {summaryTag.length > 1 ? pluralizedDescription : description}
          </StyledDiv>
        </>
      )
    }
    return
  }

  return (
    <FlexBox className="hc-ma-normal">
      {!!purchaseOrderNumbers.length &&
        renderSummaryTag(
          purchaseOrderNumbers,
          'Purchase Order',
          'Purchase Orders',
          false,
        )}
      {!!shipmentsReviewedTracker.length &&
        renderSummaryTag(
          shipmentsReviewedTracker,
          'Shipment Reviewed',
          'Shipments Reviewed',
          !!purchaseOrderNumbers.length,
        )}
      {!!shipmentsCancelledTracker &&
        renderSummaryTag(
          shipmentsCancelledTracker,
          'Shipment Cancelled ',
          'Shipments Cancelled',
          !!purchaseOrderNumbers.length || !!shipmentsReviewedTracker.length,
        )}
      {!!shipmentsModifiedTracker.length &&
        renderSummaryTag(
          shipmentsModifiedTracker,
          'Shipment Updated',
          'Shipments Updated',
          !!purchaseOrderNumbers.length ||
            !!shipmentsReviewedTracker.length ||
            !!shipmentsCancelledTracker.length,
        )}
    </FlexBox>
  )
}

export default Summary
