export const pickupDateChangeReasons = {
  INVENTORY_NOT_READY: {
    label: 'Inventory Not Ready',
    value: 'Inventory Not Ready',
    disabled: false,
  },
  NO_PICKUP_APPOINTMENTS_AVAILABLE: {
    label: 'No Pickup Appointment Available/Business Closed',
    value: 'No Pickup Appointment Available/Business Closed',
    disabled: false,
  },
  OVER_VOP_OUTBOUND_DAILY_CAPACITY: {
    label: 'Approved Load Leveling - Over VOP Outbound Daily Capacity',
    value: 'Approved Load Leveling - Over VOP Outbound Daily Capacity',
    disabled: true,
  },
  TGT_ADJUSTED_QUANTITY: {
    label: 'Target Adjusted Quantity',
    value: 'Target Adjusted Quantity',
    disabled: false,
  },
  TGT_DRIVEN_PICKUP_DATE_OVERRIDE: {
    label: 'Target Driven Pickup Date Override',
    value: 'Target Driven Pickup Date Override',
    disabled: true,
  },
}

export const consolPickupDateChangeReasons = {
  FACILITY_DRIVEN_PICKUP_DATE_OVERRIDE: {
    label: 'Facility Driven Pickup Date Override',
    value: 'Facility Driven Pickup Date Override',
    disabled: false,
  },
}
