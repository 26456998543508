import { useMemo, useState, useEffect } from 'react'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import useSyncShipmentsByPOs from 'services/syncShipmentsByPOs'
import { useQueryClient } from 'react-query'
import FlexBox from 'components/FlexBox'
import { Acknowledgement } from 'components/Acknowledgement/Acknowledement'
import ModifiedShipmentsTable from './modified'
import { useShipmentManagementContext } from 'context/ShipmentManagementContext'
import { findInvalidBookingVersion } from './utils'
import { useGlobalContext } from 'context/GlobalContext'
import { PurchaseOrderInterface, TloAlert } from '../../../common/types'
import { Button, Heading, List } from '@enterprise-ui/canvas-ui-react'
import {
  useShipmentsModificationQuery,
  useShipmentsLoadModificationQuery,
  useShipmentsCancellationsQuery,
} from '../../../hooks/reactQuery/postQueryHooks'
import { useShipmentsByTloAlerts } from 'hooks/reactQuery/getQueryHooks'
import { useAnalyticsWrapper } from 'hooks/useAnalyticsWrapper'
import { useNavigate } from 'react-router-dom'
import { shipmentsModified } from 'components/Shipments/utils'

export interface AllShipmentAdjustment {
  isComplete: boolean
  failedAdjustments: any
}
const SubmitChanges = () => {
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [alerts, setAlerts] = useState<TloAlert[]>([])
  const [isAlertCheckDone, setIsAlertCheckDone] = useState<boolean>(false)
  const analyticsWrapper = useAnalyticsWrapper()
  const [isSearchTloSubmit, setIsSearchTloSubmit] = useState<boolean>(false)
  const { shipmentManagementState } = useShipmentManagementContext()
  const { globalState, globalDispatch } = useGlobalContext()
  const vendorIds: number[] =
    globalState.filters.shipmentManagement.vendorIds || []

  const purchaseOrders: Array<PurchaseOrderInterface> = Object.values(
    shipmentManagementState.shipmentsByPurchaseOrderKey,
  )

  const shipmentsFromPurchaseOrders = purchaseOrders.reduce(
    (prev: Array<any>, curr: any) => {
      return [...prev, ...Object.values(curr)]
    },
    [],
  )

  const shipmentsFromShipmentView =
    Object.values(shipmentManagementState.shipmentsViewData) || []

  const shipments = [
    ...shipmentsFromPurchaseOrders.filter(shipmentsModified),
    ...shipmentsFromShipmentView.filter(shipmentsModified),
  ]

  const vendors: number[] = shipments.reduce((acc, shipment) => {
    if (!acc.includes(shipment.vendorId)) {
      acc.push(shipment.vendorId)
    }
    return acc
  }, [])

  const makeToast = useToaster()
  const tloShipmentAlerts = useShipmentsByTloAlerts(vendors, shipments)

  const hasRequiredEntitlements = globalState.userInfo.shipmentManagement.write
  const [syncSettings, setSyncSettings] = useState({
    uid: 1,
    checkVersion: true,
    hasRequiredEntitlements,
    failedModifiedShipments: [],
  })

  // Show latest Shipments
  const { syncShipmentsByPOsState } = useSyncShipmentsByPOs(
    shipments,
    syncSettings,
  )

  const invalidateQueriesAndClose = () => {
    // avoid race condition that occasionally occurs if you refresh data immediately after posting modification
    setTimeout(() => {
      queryClient.invalidateQueries('shipments-by-po')
      queryClient.invalidateQueries('shipments-by-filters')
      setIsSubmitting(false)
      handleClose()
    }, 2000)
  }

  const queryClient = useQueryClient()
  const {
    mutate: modifyShipments,
    data: modifyShipmentsResponse,
    isSuccess: modifyShipmentsIsSuccess,
    isError: modifyShipmentsIsError,
    isLoading: modifyShipmentsIsLoading,
  } = useShipmentsModificationQuery()
  const {
    mutate: modifyShipmentsLoad,
    data: modifyShipmentsLoadResponse,
    isSuccess: modifyShipmentsLoadIsSuccess,
    isError: modifyShipmentsLoadIsError,
    isLoading: modifyShipmentsLoadIsLoading,
  } = useShipmentsLoadModificationQuery()
  const {
    mutate: cancelShipments,
    data: cancelShipmentsResponse,
    isSuccess: cancelShipmentsIsSuccess,
    isError: cancelShipmentsIsError,
    isLoading: cancelShipmentsIsLoading,
  } = useShipmentsCancellationsQuery()
  const allShipmentAdjustments: AllShipmentAdjustment = useMemo(() => {
    return {
      isComplete: false,
      failedAdjustments: [],
    }
  }, [])
  const handleClose = () => {
    globalDispatch({
      payload: null,
      type: 'SET_MODAL',
    })
  }
  if (
    modifyShipmentsIsSuccess &&
    modifyShipmentsLoadIsSuccess &&
    cancelShipmentsIsSuccess
  ) {
    allShipmentAdjustments.isComplete = true
    allShipmentAdjustments.failedAdjustments = [
      ...(modifyShipmentsResponse || []),
      ...(modifyShipmentsLoadResponse || []),
      ...(cancelShipmentsResponse || []),
    ]
    if (allShipmentAdjustments.failedAdjustments.length === 0) {
      if (!isSearchTloSubmit) {
        invalidateQueriesAndClose()
      }
    }
  }
  if (
    modifyShipmentsIsError &&
    modifyShipmentsLoadIsError &&
    cancelShipmentsIsError
  ) {
    allShipmentAdjustments.isComplete = true
    allShipmentAdjustments.failedAdjustments = shipments
  }

  const hasFailures = allShipmentAdjustments.failedAdjustments.length > 0

  // Get latest shipments
  useEffect(() => {
    if (allShipmentAdjustments.isComplete) {
      setSyncSettings({
        uid: Date.now(),
        checkVersion: false,
        hasRequiredEntitlements,
        failedModifiedShipments: allShipmentAdjustments.failedAdjustments,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allShipmentAdjustments.isComplete,
    hasRequiredEntitlements,
    setSyncSettings,
  ])

  useEffect(() => {
    if (
      syncShipmentsByPOsState.isComplete &&
      syncSettings.uid > 1 &&
      allShipmentAdjustments.failedAdjustments.length <= 0
    ) {
      setTimeout(() => {
        if (!isSearchTloSubmit) {
          invalidateQueriesAndClose()
        } else {
          const tloIds = tloShipmentAlerts.map((tlo) => tlo.data?.id)
          analyticsWrapper?.trackCustomEvent(
            'Submit & Go To TLO Related Shipments',
            'tlo ids',
            JSON.stringify(tloIds),
            'submit and go to TLO shipments from submit modal',
          )
          navigate(
            `/shipment-management?query={"truckLoadOptimizationRefIds":[${tloIds}]${
              vendorIds.length ? ',"vendorIds":[' + vendorIds + ']' : ''
            }}`,
          )
          navigate(0)
        }
      }, 1000)
    }
    // eslint-disable-next-line
  }, [allShipmentAdjustments, syncShipmentsByPOsState, syncSettings])

  useEffect(() => {
    if (shipments.length <= 0) {
      setTimeout(() => {
        handleClose()
      }, 100)
    }
    // eslint-disable-next-line
  }, [shipments])

  const handleOnSubmit = async (isSearchTlo = false) => {
    setIsSearchTloSubmit(isSearchTlo)
    setIsSubmitting(true)
    modifyShipments({ shipments: shipments, uid: Date.now() })
    modifyShipmentsLoad({ shipments: shipments, uid: Date.now() })
    cancelShipments({ shipments: shipments, uid: Date.now() })
  }

  const foundShipmentWithBookingVersionInvalid = findInvalidBookingVersion(
    shipments,
    shipmentManagementState,
  )

  const hasInvalidBookingVersion = !!foundShipmentWithBookingVersionInvalid

  const shipmentsOnLoad = shipments.filter((shipment: any) => {
    return shipment.loadId
  })

  const hasShipmentsOnLoad = shipmentsOnLoad.length > 0

  const hasMissingCommodityTypes =
    shipments.filter((shipment: any) => {
      return !shipment.commodityCode
    }).length > 0

  const hasShipmentCancel =
    shipments.filter((shipment: any) => {
      return shipment.toBeCancelled
    }).length > 0

  if (tloShipmentAlerts.every((result) => result.isSuccess)) {
    if (!isAlertCheckDone && !alerts.length) {
      const tloAlerts = tloShipmentAlerts
        .map((result) => result.data)
        .filter((result) => result)
      setIsAlertCheckDone(true)
      setAlerts(tloAlerts as TloAlert[])
    }
  }
  useEffect(() => {
    if (alerts.length) {
      alerts.forEach((alert) => makeToast(alert))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts])
  return (
    <div className="hc-pa-normal" data-testid="shipment-checkout-modal">
      {hasInvalidBookingVersion && (
        <>
          <Heading size={5} className="hc-fs-xl">
            Shipments to Review and Submit
          </Heading>
          <br />
          <Heading size={6} className="hc-fs-lg">
            We detected newer shipment version(s) that may have conflicts with
            your modifications. Please review the shipments again and submit for
            update.
          </Heading>
          <br />
          <FlexBox justify="flex-start">
            <Button
              onClick={() => handleClose()}
              className="hc-mr-min"
              variant="outlined"
            >
              Review Again
            </Button>
          </FlexBox>
        </>
      )}
      {!hasInvalidBookingVersion && !hasFailures && (
        <>
          <Heading size={5} className="hc-fs-xl">
            Shipments to Review and Submit
          </Heading>
          <span>Please verify that all modifications are correct.</span>
          <ModifiedShipmentsTable shipments={shipments} />
          <br />
          {!hasMissingCommodityTypes && hasShipmentCancel && (
            <Acknowledgement hasShipmentsOnLoad={hasShipmentsOnLoad} />
          )}
          {hasMissingCommodityTypes && (
            <List>
              <List.Item className="hc-bg-error">
                One or more of the shipments being reviewed does not have a
                commodity code selected. Please select a commodity code for all
                shipments any try again.
              </List.Item>
            </List>
          )}
          <FlexBox justify="flex-end" style={{ marginTop: '12px' }}>
            <Button
              data-testid="shipment-checkout-cancel-button"
              onClick={() => handleClose()}
              className="hc-mr-sm"
              type="secondary"
              disabled={
                modifyShipmentsIsLoading ||
                modifyShipmentsLoadIsLoading ||
                cancelShipmentsIsLoading
              }
            >
              Cancel
            </Button>
            <Button
              data-testid="shipment-checkout-submit-changes-button"
              onClick={() => handleOnSubmit()}
              type="primary"
              isLoading={
                isSubmitting ||
                modifyShipmentsIsLoading ||
                modifyShipmentsLoadIsLoading ||
                cancelShipmentsIsLoading
              }
              disabled={hasMissingCommodityTypes}
            >
              Submit Changes
            </Button>
            {!!tloShipmentAlerts.filter((result) => result.data).length && (
              <Button
                className="hc-ml-sm"
                data-testid="checkout-submit-search-tlo-button"
                onClick={() => handleOnSubmit(true)}
                type="primary"
                isLoading={
                  modifyShipmentsIsLoading ||
                  modifyShipmentsLoadIsLoading ||
                  cancelShipmentsIsLoading
                }
                disabled={hasMissingCommodityTypes}
              >
                Submit and Go To TLO Related Shipments
              </Button>
            )}
          </FlexBox>
        </>
      )}
      {hasFailures && (
        <>
          <Heading size={5}>Shipments to Review and Submit</Heading>
          <br />
          <ModifiedShipmentsTable
            shipments={allShipmentAdjustments.failedAdjustments}
          />
          <List>
            <List.Item
              className="hc-bg-error"
              data-testid="submit-changes-failure"
            >
              The Shipment Adjustments above failed to update. Please return to
              Shipment Management and try again later.
            </List.Item>
          </List>

          <br />
          <FlexBox justify="flex-end" style={{ marginTop: '12px' }}>
            <Button
              data-testid="shipment-checkout-cancel-button"
              onClick={() => handleClose()}
              className="hc-mr-sm"
              type="secondary"
            >
              Close
            </Button>
          </FlexBox>
        </>
      )}
    </div>
  )
}

export default SubmitChanges
