import axios from 'axios'
import { groupBy } from 'lodash'

import { getUiServicesEndpoint } from './utils'
import { TmFormatLocation, VendorLocationRelationship } from '../common/types'

export const basicLocationsEndpoint = async () => {
  const { key, url } = await getUiServicesEndpoint()
  return `${url}/v1/locations?key=${key}`
}

export const fetchBasicLocationsAndVendors = async () => {
  return axios({
    method: 'get',
    url: await basicLocationsEndpoint(),
  }).then((res) => {
    return res.data
  })
}

export const basicLocationTransform = (data: any) => {
  const locationsVendorsByAddressLine: any = groupBy(
    data,
    (item) => item.address.address_line,
  )
  return Object.values(locationsVendorsByAddressLine).map((values: any) => {
    let vendorLocationRelationshipList: VendorLocationRelationship[] = []
    values.forEach(({ vendor_location_relationships }: any) =>
      vendor_location_relationships.forEach(
        (vendorLocationArrayData: { vendor_id: number; tm_loc_id: string }) =>
          vendorLocationRelationshipList.push({
            vendorId: vendorLocationArrayData.vendor_id,
            tmLocId: vendorLocationArrayData.tm_loc_id,
          }),
      ),
    )
    return returnFormatTmLocations(values[0], vendorLocationRelationshipList)
  })
}

const returnFormatTmLocations = (
  tmLocation: Record<string, Record<string, string>>,
  vendorLocationRelationships: VendorLocationRelationship[],
): TmFormatLocation => {
  const tmLocIds: Array<string> = []
  const vendorIds: Array<number> = []
  vendorLocationRelationships.forEach(
    (vendorData: VendorLocationRelationship) => {
      tmLocIds.push(vendorData.tmLocId)
      vendorIds.push(vendorData.vendorId)
    },
  )

  const combinedAddressLine = tmLocation.address.address_line_two
    ? `${tmLocation.address.address_line}, ${tmLocation.address.address_line_two}`
    : tmLocation.address.address_line

  return {
    locationId: tmLocation.location_id,
    address: `${combinedAddressLine}, ${tmLocation.address.city}, ${tmLocation.address.state} ${tmLocation.address.country_code} ${tmLocation.address.zipcode}`,
    chipAddressDisplay: /^[0-9,.]*$/.test(combinedAddressLine)
      ? `${tmLocation.address.address_line}, ${tmLocation.address.city}`
      : tmLocation.address.address_line,
    state: tmLocation.address.state,
    tmLocIds,
    vendorIds,
  }
}
