import axios from 'axios'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import { getUiServicesEndpoint } from './utils'

export const transformLocations = (data: any) => {
  if (!Array.isArray(data)) {
    return []
  }
  const mappedLocations = data.map((item: any) => {
    const { locations, vendor_id } = item
    return locations.map((location: any, index: number) => {
      const {
        address: { address_line, address_line_two, city, state, zipCode },
        closure_dates = [],
        contacts = [],
        business_hours = [],
        is3rd_party,
        location_transportation_attributes = {},
        vmm_location_id,
        name,
      } = location

      const {
        accept_drop_trailers,
        max_outbound_cartons_per_day,
        max_outbound_trailers_per_day,
        drop_trailer_rejection_reason,
        drop_trailer_schedule,
        accept_rear_roll_door_trailers,
        roll_door_rejection_reason,
        updated_by,
        verified_by_user,
      } = location_transportation_attributes
      const mappedContacts = contacts.map((item: any) => {
        const {
          email,
          first_name,
          last_name,
          mobile_number,
          office_number,
          responsibilities,
        } = item

        return {
          email,
          firstName: first_name,
          lastName: last_name,
          mobileNumber: mobile_number,
          officeNumber: office_number,
          responsibility: responsibilities.map(
            (resObj: { responsibility: String }) => resObj.responsibility,
          ),
        }
      })

      const mappedHoursOfOperation = business_hours.map((item: any) => {
        const { close_time, day, open_time, utcoffset } = item
        return {
          closeTime: close_time,
          day: day,
          openTime: open_time,
          utCoffset: utcoffset,
        }
      })
      return {
        acceptDropTrailers: accept_drop_trailers ? 'Yes' : 'No',
        addressLine:
          address_line + (address_line_two ? `, ${address_line_two}` : ''),
        city: city,
        closure: closure_dates,
        contacts: mappedContacts,
        hoursOfOperation: mappedHoursOfOperation,
        id: `${vmm_location_id}-${index}`,
        is3rdParty: is3rd_party,
        isFailedUpdate: false,
        lastSavedBy: updated_by,
        locationId: vmm_location_id,
        maxOutboundCartonsPerDay: max_outbound_cartons_per_day,
        maxOutboundTrailersPerDay: max_outbound_trailers_per_day,
        dropTrailerRejectionReason: drop_trailer_rejection_reason,
        dropTrailerSchedule: drop_trailer_schedule,
        acceptRearRollDoorTrailers: accept_rear_roll_door_trailers
          ? 'Yes'
          : 'No',
        rollDoorRejectionReason: roll_door_rejection_reason,
        name: name,
        original_acceptDropTrailers: accept_drop_trailers ? 'Yes' : 'No',
        original_maxOutboundCartonsPerDay: max_outbound_cartons_per_day,
        original_maxOutboundTrailersPerDay: max_outbound_trailers_per_day,
        state: state,
        vendorId: vendor_id,
        verifiedByUser: verified_by_user ? 'Yes' : 'No',
        zipCode: zipCode,
      }
    })
  })

  const locations = mappedLocations.flat()

  return orderBy(locations, ['addressLine'], ['asc', 'asc'])
}

export const transformProducts = (products: any, locations: any) => {
  if (!Array.isArray(products)) {
    return []
  }

  const reducedAddressFromLocations = Object.values(locations)
    .map((item: any) => {
      return item.locations
    })
    .flat()
    .reduce((prev: any, curr: any) => {
      return {
        ...prev,
        [curr.vmm_location_id]: {
          addressLine: get(curr, 'address.address_line', ''),
          city: get(curr, 'address.city', ''),
          state: get(curr, 'address.state', ''),
        },
      }
    }, {})

  const mappedProducts = products.map((item: any, index: number) => {
    const {
      default_commodity,
      department,
      updated_by,
      palletized,
      pallets_stackable,
      pallet_volume,
      vendor_id,
      verified_by_user,
      vmm_location_id,
    } = item

    return {
      addressLine: get(
        reducedAddressFromLocations,
        `${item.vmm_location_id}.addressLine`,
        '',
      ),
      city: get(
        reducedAddressFromLocations,
        `${item.vmm_location_id}.city`,
        '',
      ),
      defaultCommodity: default_commodity || 'Please Select a Commodity',
      department: department,
      id: `${department}-${vmm_location_id}-${index}`,
      isPalletized: palletized ? 'Yes' : 'No',
      isPalletsStackable: pallets_stackable ? 'Yes' : 'No',
      palletVolume: pallet_volume,
      isFailedUpdate: false,
      locationId: vmm_location_id,
      lastSavedBy: updated_by,
      original_defaultCommodity: default_commodity,
      original_isPalletized: palletized ? 'Yes' : 'No',
      original_isPalletsStackable: pallets_stackable ? 'Yes' : 'No',
      original_verifiedByUser: verified_by_user ? 'Yes' : 'No',
      state: get(
        reducedAddressFromLocations,
        `${item.vmm_location_id}.state`,
        '',
      ),
      vendorId: vendor_id,
      verifiedByUser: verified_by_user ? 'Yes' : 'No',
    }
  })

  return orderBy(
    mappedProducts,
    ['department', 'locationId', 'addressLine'],
    ['asc', 'asc', 'asc'],
  )
}
export const getLocationsAndProducts = async (vendorList: Array<String>) => {
  if (!Array.isArray(vendorList) || vendorList.length <= 0) {
    return {
      locations: [],
      products: [],
    }
  }
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/products_locations?vendor_ids=${vendorList}&key=${key}`
  return axios.get(endpoint)
}
