import { purchaseOrderKey } from '../common/utils'
import mergeWith from 'lodash/mergeWith'
import cloneDeep from 'lodash/cloneDeep'
import {
  LocationInterface,
  ProductInterface,
  ShipmentRowInterface,
} from 'common/types'
import { transformShipments } from 'services/transformShipments'
import { isValidProperties } from 'components/ShipmentsList/utils'
import { RowProps } from 'components/Modal/components/ChangePickupDate'

// Update products or locations
export const updateItem = (
  items: Array<LocationInterface | ProductInterface> = [],
  payload: any,
) => {
  if (!payload || !items) {
    return
  }
  const {
    field,
    id,
    status = '',
    value,
    isFailedUpdate = false,
    hasMissingFields = false,
    hasNegativeNumberValues = false,
    hasNegativeOrZeroNumberValues = false,
    cannotBeVerified = false,
    palletVolumeStackableMaxError = false,
    palletVolumeNonStackableMaxError = false,
    palletVolumeMaxError = false,
    maxOutboundTrailersPerDayError = false,
    maxOutboundCartonsPerDayError = false,
  } = payload

  if (id === undefined) {
    return
  }

  const hasFieldValue = field !== undefined && value !== undefined

  return items.map((item: LocationInterface | ProductInterface) => {
    if (item.id === id) {
      return {
        ...item,
        hasMissingFields,
        isFailedUpdate,
        hasNegativeNumberValues,
        hasNegativeOrZeroNumberValues,
        cannotBeVerified,
        palletVolumeStackableMaxError,
        palletVolumeNonStackableMaxError,
        palletVolumeMaxError,
        maxOutboundTrailersPerDayError,
        maxOutboundCartonsPerDayError,
        ...(hasFieldValue && { [field]: value }),
        status,
      }
    }
    return item
  })
}

export const isShipmentModified = (shipment: any) => {
  // Get keys that have "original_" prepended, but only return what comes after "original_"
  const filteredKeysByRegex = Object.keys(shipment).reduce(
    (prev: any, curr: string) => {
      const regex = /(?<=original_).+/
      const matchedKeyByRegex = curr.match(regex)

      if (matchedKeyByRegex) {
        return [...prev, matchedKeyByRegex[0]]
      }
      return prev
    },
    [],
  )

  // Check for modifications
  const foundModification = filteredKeysByRegex.find((key: string) => {
    return shipment[key] !== shipment[`original_${key}`]
  })

  return !!foundModification
}

interface UpdateShipmentPayloadInterface {
  shipment: object
  field: string
  value: string
}

export const updateShipment = (payload: UpdateShipmentPayloadInterface) => {
  if (
    typeof payload !== 'object' ||
    payload === null ||
    typeof payload?.shipment !== 'object'
  ) {
    throw new Error('mal formed paramaters')
  }

  const { shipment, field = '', value } = payload

  const updatedShipment = {
    ...shipment,
    [field]: value,
  }

  return {
    ...updatedShipment,
    isModified: isShipmentModified(updatedShipment),
    hasInvalidDimensions: !isValidProperties(updatedShipment),
  }
}

const getFailedModifiedShipmentsId = (
  failedModifiedShipments: Array<Object>,
) => {
  if (Array.isArray(failedModifiedShipments)) {
    return failedModifiedShipments?.map((shipment: any) => {
      return shipment.id
    })
  }
  return []
}

export const mergeShipmentsByPurchaseOrders = (
  prevShipmentsByPurchaseOrders: any,
  currShipmentsByPurchaseOrders: any,
  checkVersion: boolean,
  hasRequiredEntitlements: boolean,
  failedModifiedShipments: Array<string>,
  isInternal: boolean,
) => {
  const mappedPurchaseOrdersWithShipments = currShipmentsByPurchaseOrders.map(
    (purchaseOrder: any) => {
      if (purchaseOrder?.value?.purchaseOrderNumber) {
        const {
          value: { purchaseOrderNumber, shipments, department, vmmLocationId },
        } = purchaseOrder
        return {
          purchaseOrderNumber,
          department,
          shipments: transformShipments({
            rawShipments: shipments,
            hasRequiredEntitlements,
            vmmLocationId,
            isInternal,
          }),
        }
      }
      return {}
    },
    [],
  )

  const mergeCustomizer = (
    objValue: ShipmentRowInterface & RowProps,
    srcValue: ShipmentRowInterface,
  ): ShipmentRowInterface & RowProps => {
    const failedModifiedShipmentsId = getFailedModifiedShipmentsId(
      failedModifiedShipments,
    )
    // We check to see if the booking versions or different from the ui compared to the service.
    const isBookingVersionInvalid =
      checkVersion && srcValue?.bookingVersion !== objValue?.bookingVersion
    const isFailedModifiedShipment = failedModifiedShipmentsId.includes(
      srcValue?.tgtBookingId,
    )
    return {
      // objValue are existing values in the ui - enriched with vendor data (RowProps defined in ChangePickupDate.tsx, i.e. businessHours, businessHrsBasedPickup)
      ...objValue,
      // srcValue are new values from the service
      ...srcValue,
      appointmentNumber: isBookingVersionInvalid
        ? srcValue?.appointmentNumber
        : objValue?.appointmentNumber,
      bol: isBookingVersionInvalid ? srcValue?.bol : objValue?.bol,
      carton: isBookingVersionInvalid ? srcValue?.carton : objValue?.carton,
      commodityCode: isBookingVersionInvalid
        ? srcValue?.commodityCode
        : objValue?.commodityCode,
      hasFailedModification: isFailedModifiedShipment,
      pallet: isBookingVersionInvalid ? srcValue?.pallet : objValue?.pallet,
      pickupDate: isBookingVersionInvalid
        ? srcValue?.pickupDate
        : objValue?.pickupDate,
      original_pickupReasonCode: isBookingVersionInvalid
        ? srcValue?.original_pickupReasonCode
        : objValue?.original_pickupReasonCode,
      pickupReasonCode: isBookingVersionInvalid
        ? srcValue?.pickupReasonCode
        : objValue?.pickupReasonCode,
      totalVol: isBookingVersionInvalid
        ? srcValue?.totalVol
        : objValue?.totalVol,
      totalWt: isBookingVersionInvalid ? srcValue?.totalWt : objValue?.totalWt,
      toBeCancelled: checkVersion ? objValue?.toBeCancelled : false,
      isModified:
        checkVersion || isFailedModifiedShipment ? objValue?.isModified : false,
      isReviewed: checkVersion ? objValue?.isReviewed : false,
      // New shipments don't have existing booking versions, so we don't want to mark thoes as invalids
      isBookingVersionInvalid: !!(
        objValue?.bookingVersion && isBookingVersionInvalid
      ),
      businessHours: objValue?.businessHours,
      businessHrsBasedPickup: objValue?.businessHrsBasedPickup,
      closureDates: objValue?.closureDates,
    }
  }

  return mappedPurchaseOrdersWithShipments.reduce((prev: any, curr: any) => {
    return {
      ...prev,
      [purchaseOrderKey(curr)]: mergeWith(
        cloneDeep(prevShipmentsByPurchaseOrders[purchaseOrderKey(curr)]),
        curr.shipments,
        mergeCustomizer,
      ),
    }
  }, {})
}
